import React from "react";
import MaterialTable from "material-table";

const List = (props) => {
  const { repos } = props;
  if (!repos || repos.length === 0) return <p>Sin registros</p>;

  return (
    <div>
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[
            { title: "Código", field: "Codigo" },
            { title: "Descripción", field: "Nombre" },
            { title: "Marca", field: "Marca" },
            { title: "Modelo", field: "Modelo" },
            {
              title: "Precio 1",
              field: "Precio1",
              type: "numeric",
              render: (rowData) => <span>Q {rowData["Precio1"]}</span>,
            },
            {
              title: "Precio 2",
              field: "Precio2",
              type: "numeric",
              render: (rowData) => <span>Q {rowData["Precio2"]}</span>,
            },
            {
              title: "Precio 3",
              field: "Precio3",
              type: "numeric",
              render: (rowData) => <span>Q {rowData["Precio3"]}</span>,
            },

            {
              title: "CENTRAL",
              field: "CENTRAL",
              type: "numeric",
              align: "center",
            },
            {
              title: "BARBER SHOP",
              field: "BARBER SHOP",
              type: "numeric",
              align: "center",
            },
            {
              title: "CLINIC DELUXE",
              field: "CLINIC DELUXE",
              type: "numeric",
              align: "center",
            },
            {
              title: "CENTRO MARYS",
              field: "CENTRO MARYS",
              type: "numeric",
              align: "center",
            },
            {
              title: "PRADERA",
              field: "PRADERA",
              type: "numeric",
              align: "center",
            },
            {
              title: "ZENTRO PLAZA",
              field: "ZENTRO PLAZA",
              type: "numeric",
              align: "center",
            },
            {
              title: "Total",
              field: "Total",
              type: "numeric",
              align: "center",
            },
          ]}
          data={repos}
          options={{
            search: false,
            filtering: true,
            align: "center",
            exportButton: true,
            exportAllData: true,
            headerStyle: {
              backgroundColor: "#2B3E50",
              color: "#fff",
            },
            pageSize: 20,
            pageSizeOptions: [20, 30, 50],
          }}
          title="Existencias de Inventario"
        />
      </div>
    </div>
  );
};
export default List;
